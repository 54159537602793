var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "products category-leaflet" },
    [
      _c("Breadcrumb", { attrs: { items: _vm.breadcrumbs } }),
      _c("category-block", {
        staticClass: "category-block category-block-header-1",
        attrs: { target: _vm.category, position: "position1" }
      }),
      _c(
        "v-container",
        { staticClass: "py-0" },
        [
          _c("CategoryTitle", {
            key: _vm.category.categoryId,
            attrs: {
              category: _vm.category,
              selectedCategory: _vm.selectedCategory,
              count: _vm.count
            }
          })
        ],
        1
      ),
      _vm.categoryId
        ? _c(
            "v-container",
            [
              _c("ProductListGrid", {
                key: _vm.category.categoryId,
                attrs: {
                  fluid: _vm.$vuetify.breakpoint.xsOnly,
                  parentCategoryId: _vm.category.categoryId,
                  categoryId: _vm.categoryId,
                  hidePromoFilter: true,
                  hideFilterString: "Categorie,Main Term"
                },
                on: { productsCount: _vm.updateCount }
              })
            ],
            1
          )
        : _vm._l(_vm.category.children, function(subCategory) {
            return _c(
              "v-container",
              { key: subCategory.categoryId },
              [
                _c("product-list-slider", {
                  attrs: {
                    title: subCategory.name,
                    fetchCategoryId: _vm.category.categoryId,
                    categoryId: subCategory.categoryId,
                    categoryPath: _vm.category.slug,
                    paginationClass: `swiper-pagination-${subCategory.categoryId}`,
                    cols: "auto",
                    sm: "2",
                    md: "2",
                    lg: "3",
                    xl: "4"
                  }
                })
              ],
              1
            )
          }),
      _c("category-block", {
        staticClass: "category-block category-block-footer-1",
        attrs: { target: _vm.category, position: "position10" }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }